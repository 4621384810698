import styled from 'styled-components';

import { BorderMuted } from '@components/BorderMuted';
import { useAppContext } from '@contexts/AppContext';
import { MixpanelEvents, Page } from '@entities/enums';
import { useMediaIs } from '@hooks/useMediaIs';

import { CTAButton, Heading3, Margin, P, Strong } from '@ovotech/element';
import { useCustomMixpanel } from '@services/Mixpanel';
import { mqSmallUp } from '@utils/mediaQuery';
import { PrimaryCTA } from '@components/PrimaryCTA';

const GreenText = styled.span`
  ${({ theme }) => `
    color: ${theme.semantic.message.branded};
  `}
`;

const Wrapper = styled('div')`
  ${({ theme }) => `
    display: flex;
    gap: ${theme.core.space[3]}; 
    
    > div:nth-child(odd) {
        flex: 1;
      }
  `}
`;

const VerticalLine = styled('div')`
  ${({ theme }) => `
    display: flex;
    width: 1px;
    margin-inline: ${theme.core.space[5]};
    background-color: ${theme.core.color.neutral.lighter};
    height: 100px;
    align-self: center;
  `}
`;

const BannerText = styled(P)`
  ${mqSmallUp('min-height: 80px;')}
`;

const StyledHeading3 = styled(Heading3)`
  ${({ theme }) => `
    color: ${theme.semantic.message.base};
    font-weight: ${theme.core.fontWeight.bold};
  `}
`;

const StyledBannerWrapper = styled.div`
  button {
    max-width: 300px;
  }
`;

const CustomerLogInSection = () => {
  const { navigateToLogin } = useAppContext();
  const { track } = useCustomMixpanel();

  return (
    <StyledBannerWrapper>
      <BannerText>
        <Strong>Already an OVO customer?</Strong>
        <Margin vertical={1} />
        <P>
          Log in to get your{' '}
          <GreenText>
            <strong>free</strong>{' '}
          </GreenText>
          repair + our Complete cover.{' '}
        </P>
      </BannerText>
      <Margin bottom={4} />
      <PrimaryCTA
        fullWidth
        variant="primary"
        onClick={() => {
          track(MixpanelEvents.BUTTON_CLICK, {
            buttonId: 'getYourFreeRepair',
            pageName: 'home-recover',
          });
          navigateToLogin(Page.HOME_RECOVER);
        }}
      >
        Get your free repair
      </PrimaryCTA>
    </StyledBannerWrapper>
  );
};

const NotCustomerText = ({ onContinue }: { onContinue: () => void }) => {
  const { track } = useCustomMixpanel();

  return (
    <StyledBannerWrapper>
      <BannerText>
        <strong>Not with OVO?</strong>
        <Margin vertical={1} />
        <P>
          Get your repair for{' '}
          <GreenText>
            <Strong>£79</Strong>{' '}
          </GreenText>
          when you take out our Complete cover.
        </P>
      </BannerText>
      <Margin vertical={4} />
      <CTAButton
        fullWidth
        data-testid="repair-login-banner-continue"
        variant="secondary"
        type="button"
        onClick={() => {
          track(MixpanelEvents.BUTTON_CLICK, {
            buttonId: 'bookRepair79',
            pageName: 'home-recover',
          });
          onContinue();
        }}
      >
        Book repair for £79
      </CTAButton>
    </StyledBannerWrapper>
  );
};

export const RepairLoginBanner = ({
  onContinue,
}: {
  onContinue: () => void;
}) => {
  const isMobile = useMediaIs('mobile', 'max');

  if (isMobile) {
    return (
      <div>
        <StyledHeading3>Get a repair +</StyledHeading3>
        <StyledHeading3>our Complete cover.</StyledHeading3>
        <Margin vertical={6} />
        <CustomerLogInSection />
        <Margin vertical={6} />
        <BorderMuted />
        <Margin vertical={5} />
        <NotCustomerText onContinue={onContinue} />
        <Margin vertical={3} />
      </div>
    );
  } else {
    return (
      <div>
        <StyledHeading3>Get a repair + our Complete cover</StyledHeading3>
        <Margin vertical={6} />
        <Wrapper>
          <CustomerLogInSection />
          <VerticalLine />
          <NotCustomerText onContinue={onContinue} />
        </Wrapper>
      </div>
    );
  }
};
